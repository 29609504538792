export function generateAssetZenURL(file_name, file_ext = "assetzen", width = 1920, height = 1080, q = 70) {
    if (file_ext == "assetzen") {
        let assetZenUrl = `https://i.${file_ext}.net/i/${file_name}/w:${width}/h:${height}/q:${q}.jpg`;
        return assetZenUrl;
    } else {
        return null;
    }
}

export function getTodayDateString() {
    const today = new Date().toISOString().slice(0, 10);
    console.log("today: " + today);
    return today;
}

export function getTomorrowDateString() {
    var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var day = currentDate.getDate();
    var month = currentDate.getMonth() + 1;
    var year = currentDate.getFullYear();
    const tomorrow = year + "-" + month + "-" + day;
    console.log("tomorrow: " + tomorrow);
    return tomorrow;
}

export const formatTime = (string) => {
    return (
        (new Array(length + 1).join("0") + Math.floor(string / 60)).slice(-2) +
        ":" +
        (new Array(length + 1).join("0") + (string % 60)).slice(-2)
    );
};

export const convertDateForWbeSearch = (date) => {
    // Return mm-dd-yyyy format (very odd!)
    let formattedDate =
        (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
        "-" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "-" +
        date.getFullYear();
    return formattedDate;
};

export const convertDateStringForWbeSearch = (dateString) => {
    // Return mm-dd-yyyy format (very odd!)
    let date = new Date(dateString);
    let formattedDate =
        (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
        "-" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "-" +
        date.getFullYear();
    return formattedDate;
};

export const convertDateStringToDatetime = (dateString) => {
    // Return yyyy-mm-dd hh:mm:ss format
    let date = new Date(dateString);
    let iso = date.toISOString().match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/);
    let datetime = iso[1] + " " + iso[2];
    return datetime;
};

export const convertDateStringToISODate = (dateString) => {
    // Return yyyy-mm-dd format
    let date = new Date(dateString);
    let isoDate = date.toISOString().split("T")[0];
    return isoDate;
};

export const convertDateStringToLongDate = (dateString) => {
    // Return Wed May 25 2022 format
    let date = new Date(dateString);
    let longDate = date.toDateString();
    return longDate;
};

export const convertDateStringToLongDateSubstring = (dateString) => {
    // Return May 25 2022 format
    let longDate = convertDateStringToLongDate(dateString);
    let substring = longDate.substring(4, 15);
    return substring;
};

export const convertDateStringToLocaleDate = (dateString) => {
    // Return locale date format
    let date = new Date(dateString);
    let localeDate = date.toLocaleDateString();
    return localeDate;
};

export const convertDollarsToCents = (dollars) => {
    return Math.round(dollars * 100);
};

export const convertCentsToDollars = (cents) => {
    return cents / 100;
};

// Converts string to boolean, if necessary
export const checkForBooleanString = (value: string) => {
    if (value === "true") {
        return true;
    } else if (value === "false") {
        return false;
    } else {
        return value;
    }
};

const crypto = require("crypto");
const algorithm = "aes-256-ctr";
const secretKey = process.env.SERVICE_ENCRYPTION_KEY;
const iv = process.env.SERVICE_ENCRYPTION_IV;

export const encrypt = (text) => {
    const cipher = crypto.createCipheriv(algorithm, Buffer.from(secretKey, "hex"), Buffer.from(iv, "hex"));
    const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
    return encrypted.toString("hex");
};

export const decrypt = (text) => {
    const decipher = crypto.createDecipheriv(algorithm, Buffer.from(secretKey, "hex"), Buffer.from(iv, "hex"));
    const decrpyted = Buffer.concat([decipher.update(Buffer.from(text, "hex")), decipher.final()]);
    return decrpyted.toString();
};

export const getCurrentBaseUrl = () => {
    if (!process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV == "development") {
        return "http://localhost:3000";
    } else if (
        process.env.NEXT_PUBLIC_VERCEL_ENV == "production" &&
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF == "master"
    ) {
        return "https://members.vacayou.com";
    } else if (
        process.env.NEXT_PUBLIC_VERCEL_ENV == "preview" &&
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF == "staging"
    ) {
        return "https://staging.members.vacayou.com";
    } else {
        return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
    }
};
