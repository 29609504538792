import React, { useContext } from "react";
import { useMultiStyleConfig } from "@chakra-ui/react";
import { AspectRatio, Box, Container } from "@chakra-ui/react";
import { HeroOverlay } from "../Overlays";
import { constants } from "../../../../util/constants";
import { UserContext } from "../../../../contexts/userContext";

const Hero = ({ aspectRatio, bgImage = null, children = null, noGradient = false }) => {
    const { userState } = useContext(UserContext);
    const variant = userState.userType.toLowerCase();
    const styles = useMultiStyleConfig("Hero", { variant });
    const { backgroundSettings } = constants;

    const heroContainer = (
        <Box bgImage={bgImage && bgImage} {...backgroundSettings}>
            <HeroOverlay display={noGradient ? "none" : "block"} />
            <Container
                height="100%"
                maxW="container.xl"
                position="relative"
                sx={styles.container}
                p={aspectRatio === "none" && "0 !important"}
            >
                {children}
            </Container>
        </Box>
    );

    return (
        <Box sx={styles.wrapper}>
            {aspectRatio === "none" ? (
                heroContainer
            ) : (
                <AspectRatio ratio={aspectRatio ? aspectRatio : 1}>{heroContainer}</AspectRatio>
            )}
        </Box>
    );
};

export default Hero;
