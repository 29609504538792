import React from "react";
import { AspectRatio, Box, Flex } from "@chakra-ui/react";
import { CardOverlay } from "../Overlays";
import { constants } from "../../../../util/constants";

const Card = ({ aspectRatio, bgImage, children, variant }) => {
    const { backgroundSettings } = constants;

    return (
        <AspectRatio ratio={aspectRatio ? aspectRatio : 1}>
            <Box boxShadow="primary" bgImage={bgImage && bgImage} {...backgroundSettings} borderRadius="10px">
                <CardOverlay variant={variant} />
                <Flex
                    height="100%"
                    width="100%"
                    position="relative"
                    flexDirection="column"
                    textAlign="left"
                    py={2}
                    px={4}
                >
                    {children}
                </Flex>
            </Box>
        </AspectRatio>
    );
};

export default Card;
