export const constants = {
    aspectRatio: {
        slim: {
            base: 3 / 1,
            sm: 5 / 1,
            md: 7 / 1,
            lg: 5 / 1,
            xl: 6 / 1,
        },
        normal: {
            base: 0.75 / 1,
            sm: 1.25 / 1,
            md: 2 / 1,
            lg: 2.5 / 1,
            xl: 3 / 1,
        },
        strong: {
            base: 0.75 / 1,
            sm: 1 / 1,
            md: 1.5 / 1,
            lg: 2 / 1,
            xl: 2.5 / 1,
        },
        xLarge: {
            base: 0.55 / 1,
            sm: 1 / 1,
            md: 1.5 / 1,
            lg: 2 / 1,
            xl: 2.5 / 1,
        },
    },
    // TODO move this to chakra theme
    backgroundSettings: {
        bgPosition: "center",
        bgSize: "cover",
        bgRepeat: "no-repeat",
    },
    // TODO I think we can make xl the default in the theme instead of importing this all over the place
    containerSize: "container.xl",
    // TODO move this to chakra theme
    gradient: {
        primary:
            "linear-gradient(to bottom, #524f38, #858266 21%, rgba(255, 255, 255, 0))",
    },
};
